<!-- eslint-disable vue/html-indent -->
<template>
<div class="wrapper">
    <div class="page">
        <div id="loader"></div>
        <div class="page-inner" id="main_page">
            <div class="va-card">
                <div class="row">
                    <div class="col-md-12">
                        <div class="dash_card_body">
                            <div class="header_section">
                                <h4 class="menu_header">Manage Auto-Replies</h4>
                                <div class="">
                                    <div class="btn-toolbar" v-if="adminRoles.includes(user.role.id)">

                                        <!-- modal for creation -->
                                        <div class="modal fade" id="autoreply" role="dialog">
                                            <div class="modal-dialog modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        Create Auto-Reply
                                                    </div>
                                                    <form @submit.prevent="createAutoReply()">
                                                        <div class="modal-body">
                                                            <div class="form-group">
                                                                <label for="criteria">Criteria</label>
                                                                 <select name="" v-model="criteria" class="form-control mt-3">
                                                                    <option value="rating">rating</option>
                                                                    <option value="general">general</option>
                                                                </select>
                                                            </div>

                                                            <div class="form-group" v-if="criteria == 'rating'">
                                                                <label for="rating">Rating:(The exact rating customers must give to receive this reply)</label>
                                                                <div class="mt-3">
                                                                    <div class="rating">
                                                                        <ul class="create_list list">
                                                                            <li :key="star" v-for="star in maxStars" :class="[{ 'five_star_rate': star <= ratings }]" @click="rate(star)" class="create_star star">
                                                                                <span class="far fa-star"></span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    
                                                                </div>
                                                                                                                                <!-- <input type="text" v-model="criteria" class="form-control" id="recipient-name"> -->
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="status">Status:</label>
                                                                <select name="" v-model="status" class="form-control mt-3">
                                                                    <option value="1">Publish</option>
                                                                    <option value="0">Unpublish</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="customer_type">Customer Type:</label>
                                                                <select name="" v-model="customerType" class="form-control mt-3">
                                                                    <option value=""> Select Customer Type</option>
                                                                    <option value="all">All</option>
                                                                    <option value="new">New</option>
                                                                    <option value="returning">Returning</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="message-text">Message:(use customer_name and store_name in place for customer name and store)</label>
                                                                <textarea v-model="message" class="form-control mt-3" id="message-text"></textarea>
                                                            </div>

                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" id="create_close_btn" class="btn btn-grey" data-dismiss="modal">Close</button>
                                                            <button type="submit" class="btn btn-orange">Save</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end -->
                                        <button @click="createModalData()" data-toggle="modal" data-target="#autoreply" class="btn btn-default custom_btn custon_orange">
                                            <i class="fa fa-plus"></i> Create Auto-Reply
                                        </button>
                                    </div>
                                </div>
                            </div>
                

                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <div class="dash_card_body">
                            <div class="review_table">
                                <div class="table_section">
                                    <table class="table  table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Criteria</th>
                                                <th scope="col">Customer Type</th>
                                                <th scope="col">Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="!loading">
                                            <tr v-for="autoreply in autoReplies" :key="autoreply.id">
                                                <td>
                                                    <span>{{autoreply.criteria.charAt(0).toUpperCase() + autoreply.criteria.slice(1)}}</span> 
                                                    <div class="rating" v-if="autoreply.criteria == 'rating'">
                                                       <br>
                                                        <ul class="list">
                                                            <li :key="star" v-for="star in maxStars" :class="[{ 'review_star_rate': star <= autoreply.ratings },{ 'five_star_rate': autoreply.ratings == maxStars }]" class="star">
                                                                <span class="far fa-star"></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                   
                                                </td>
                                                <td>
                                                    {{autoreply.customer_type.charAt(0).toUpperCase()+ autoreply.customer_type.slice(1)}}
                                                </td>
                                                <td>
                                                    <span :style="autoreply.status?'color:#149F35;': 'color:#FF0000;'">{{autoreply.status ? 'Published' : 'Unpublished'}}</span>
                                                </td>
                                                <td>
                                                    <button class="btn btn-primary btn-sm" @click="updateModalData(autoreply)" data-toggle="modal" :data-target="'#auto_reply'+autoreply.id"><i class='fas fa-edit'></i></button>
                                                </td>
                                                <!-- modal for update -->
                                                <div class="modal fade" :id="'auto_reply'+autoreply.id" role="dialog">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content">
                                                            <div class="modal-header">Create Auto-Reply
                                                            </div>
                                                            <form @submit.prevent="updateAutoReply(autoreply.id)">
                                                                <div class="modal-body">
                                                                    <div class="form-group">
                                                                        <label for="criteria">Criteria</label>
                                                                        <select name="" v-model="criteria" class="form-control mt-3">
                                                                            <option value="rating">rating</option>
                                                                            <option value="general">general</option>
                                                                        </select>
                                                                    </div>

                                                                    <div class="form-group" v-if="criteria == 'rating'">
                                                                        <label for="rating">Rating:(The exact rating customers must give to receive this reply)</label>
                                                                        <div class="mt-3">
                                                                            <div class="rating">
                                                                                <ul class="create_list list">
                                                                                    <li :key="star" v-for="star in maxStars" :class="[{ 'five_star_rate': star <= ratings }]" @click="rate(star)" class="create_star star">
                                                                                        <span class="far fa-star"></span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                                                                                        <!-- <input type="text" v-model="criteria" class="form-control" id="recipient-name"> -->
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="status">Status:</label>
                                                                        <select name="" v-model="status" class="form-control mt-3">
                                                                            <option value="1">Publish</option>
                                                                            <option value="0">Unpublish</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="customer_type">Customer Type:</label>
                                                                        <select name="" v-model="customerType" class="form-control mt-3">
                                                                            <option value=""> Select Customer Type</option>
                                                                            <option value="all">All</option>
                                                                            <option value="new">New</option>
                                                                            <option value="returning">Returning</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="message-text">Message:(use customer_name and store_name in place for customer name and store)</label>
                                                                        <textarea v-model="message" class="form-control mt-3" id="message-text"></textarea>
                                                                    </div>

                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" :id="'update_close_btn'+autoreply.id" class="btn btn-grey" data-dismiss="modal">Close</button>
                                                                    <button type="submit" class="btn btn-orange">Update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end -->
                                            </tr>
                                        </tbody>
                                        <tfoot v-if="!loading">
                                            <tr>
                                                <td colspan="8">
                                                    <div id="pagination" v-if="totalPages > 1">
                                                        <ul class="showItems">
                                                            <li>Show Items:
                                                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                                    <option :value="10">10</option>
                                                                    <option :value="20">20</option>
                                                                    <option :value="30">30</option>
                                                                    <option :value="40">40</option>
                                                                    <option :value="50">50</option>
                                                                </select>
                                                            </li>

                                                        </ul>
                                                        <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                                                    </div>

                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div v-if="loading" class="loading-cover">
                                    <div class="loader"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            autoReplies: [],
            // configuresStores:[],
            loading: false,
            spinner: false,
            page: 1,
            reviewQuestions: [],
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            search: '',
            errors: false,
            errorMsg: [],
            debounce: null,
            maxStars: 5,
            customerType: '',
            status: 1,
            criteria: 'rating',
            message: '',
            ratings:''
        }
    },
    validations() {
        return {
            criteria: { required },
            status: { required },
            message: { required },
            customerType: { required }
        }
    },
    created: async function () {
        this.getAutoReplies();
    },
    methods: {
        searchReviewQuestions() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getAutoReplies();
            }, 1500)
        },
        rate(star){
            this.ratings = star;
        },
        updateModalData(data) {
            this.ratings = data.ratings;
            this.criteria = data.criteria;
            this.message = data.message;
            this.customerType = data.customer_type;
            this.status = data.status;
        },
        createModalData() {
            this.ratings = '',
            this.criteria = 'rating';
            this.message = '';
            this.customerType = '';
            this.status = 1;
        },
        async createAutoReply() {
            this.errors = false;
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/create-autoreply`;
            const payload = {
                'criteria': this.criteria,
                'status': this.status,
                'merchant_id': this.merchantID,
                'message': this.message,
                'customer_type': this.customerType,
                'ratings': this.ratings
            }
            try {
                const response = await this.axios.post(url, payload, config)
                if (response.status === 201) {
                    this.getAutoReplies();
                    toast.success('Auto-Reply created successfully');
                    document.getElementById('create_close_btn').click();
                }
            } catch (error) {
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors;
                }
            }
            this.spinner = false
        },
        async updateAutoReply(id) {
            this.errors = false;
            this.spinner = id;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/update-autoreply/${id}`;
            const payload = {
                'criteria': this.criteria,
                'status': this.status,
                'merchant_id': this.merchantID,
                'message': this.message,
                'customer_type': this.customerType,
                'ratings': this.ratings
            }
            try {
                const response = await this.axios.put(url, payload, config)
                if (response.status === 200) {
                    this.spinner = false
                    this.getAutoReplies()
                    toast.success('Auto-Reply updated successfully')
                    document.getElementById('update_close_btn' + id).click();
                    this.spinner = false
                }
            } catch (error) {
                if (error.response.status === 400) {
                    this.errors = true
                    this.errorMsg = error.response.data.errors
                }
            }
            this.spinner = false
        },
        async getAutoReplies() {
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.dashBaseUrl}/autoreplies/${this.merchantID}`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            if (this.search != '') {
                url.searchParams.set('search', this.search);
            }
            try {
                const response = await this.axios.get(url, config)
                if (response.status === 200) {
                    this.autoReplies = response.data.data.data;
                    this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.data.total
                    this.loading = false
                }
            } catch (error) {
                this.loading = false;
            }
        },
        onPageChange(page) {
            this.page = page
            this.getAutoReplies();
        },
        onChangeRecordsPerPage() {
            this.getAutoReplies();
        },

    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>


<style scoped lang="scss">
  $active-color: #f3d23e;
  .create_list {
    margin: 0 0 5px 0;
    padding: 0;
    list-style-type: none;
    &:hover {
      .create_star {
        color: $active-color;
      }
    }
  }
  .create_star {
    display: inline-block;
    cursor: pointer;
    &:hover {
      &~.star {
        &:not(.active) {
          color: inherit;
        }
      }
    }
  }
</style>
